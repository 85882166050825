import React from 'react'
import { graphql } from 'gatsby'
import { PageView } from '@views'

const ContentfulPage = (props) => {
  return <PageView props={props} />
}

export const pageQuery = graphql`
  query pageQuery($pageId: String!) {
    contentfulPage(id: { eq: $pageId }) {
      id
      title
      seoMetaDescription
      color
      seoImage {
        gatsbyImageData
      }
      pageLayout {
        __typename
        ... on ContentfulPhotoTextTileGrid {
          id
          gridLayout {
            __typename
            ... on ContentfulVideoItem {
              id
              videoLink
              title
              paragraph {
                paragraph
              }
              image {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 100
                  formats: [WEBP, AUTO]
                )
                title
              }
            }
            ... on ContentfulPhotoItem {
              id
              title
              paragraph {
                paragraph
              }
              image {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 100
                  formats: [WEBP, AUTO]
                )
                title
              }
            }
          }
        }
        ... on ContentfulNewsGrid {
          id
          __typename
          title
          gridLayout {
            id
            title
            newsAgency
            articleLink
            datePublished
            newsAgencyLink
            image: articleImage {
              title
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 70
                formats: [WEBP, AUTO]
                aspectRatio: 1
              )
            }
            date: datePublished
          }
        }
        ... on ContentfulBlogPostGrid {
          id
          __typename
          title
          backgroundColor
          paginateTiles
          gridLayout {
            id
            title
            urlSlug
            coverTileDescription
            publishDate
            coverPhoto {
              title
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 100
                formats: [WEBP, AUTO]
                aspectRatio: 1.8
              )
            }
            heroImage {
              title
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 100
                formats: [WEBP, AUTO]
                aspectRatio: 1.8
              )
            }
          }
        }
        ... on ContentfulImageCarousel {
          id
          title
          carouselLayout {
            imageCaption
            image {
              title
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 70
                formats: [WEBP, AUTO]
                aspectRatio: 1
              )
            }
          }
        }
        ... on ContentfulContactForm {
          id
          title
        }
        ... on ContentfulVideo {
          id
          video {
            file {
              url
            }
          }
          title
        }
        ... on ContentfulNewsletterForm {
          id
          title
          paragraph {
            paragraph
          }
          ctaButtonOnly
          ctaButton {
            id
            text
            color
            buttonType {
              __typename
              ... on ContentfulInternalLink {
                id
                name
                link {
                  urlSlug
                }
              }
              ... on ContentfulExternalLink {
                id
                name
                externalLinkUrl {
                  externalLinkUrl
                }
              }
            }
          }
        }
        ... on ContentfulCircleImageTextGrid {
          id
          title
          itemTitleColor
          positionOfItemTitle
          itemsPerRow
          gridLayout {
            id
            title
            paragraph {
              paragraph
            }
            image {
              title
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 70
                formats: [WEBP, AUTO]
                aspectRatio: 1
              )
            }
            link {
              ... on ContentfulInternalLink {
                __typename
                id
                name
                link {
                  urlSlug
                }
              }
              ... on ContentfulExternalLink {
                __typename
                id
                name
                externalLinkUrl {
                  externalLinkUrl
                }
              }
            }
          }
        }
        # ... on ContentfulLogoGrid {
        #   id
        #   title
        #   gridLayout {
        #     id
        #     image {
        #       title
        #       file {
        #         url
        #       }
        #       gatsbyImageData(
        #         layout: FULL_WIDTH
        #         quality: 70
        #         formats: [WEBP, AUTO]
        #       )
        #     }
        #     link {
        #       ... on ContentfulInternalLink {
        #         __typename
        #         id
        #         name
        #         link {
        #           urlSlug
        #         }
        #       }
        #       ... on ContentfulExternalLink {
        #         __typename
        #         id
        #         name
        #          externalLinkUrl {
        # externalLinkUrl
        #         }
        #       }
        #     }
        #   }
        # }
        ... on ContentfulBackgroundImageHero {
          name
          id
          titleText
          ctaButton {
            id
            text
            color
            buttonType {
              __typename
              ... on ContentfulInternalLink {
                id
                name
                link {
                  urlSlug
                }
              }
              ... on ContentfulExternalLink {
                id
                name
                externalLinkUrl {
                  externalLinkUrl
                }
              }
            }
          }
          backgroundImage {
            title
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 80
              formats: [WEBP, AUTO]
            )
          }
          centerImage {
            title
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              formats: [WEBP, AUTO]
            )
            file {
              url
            }
          }
        }
        ... on ContentfulBackgroundImageWTextHero {
          id
          subtitle {
            subtitle
          }
          title

          image {
            title
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 80
              formats: [WEBP, AUTO]
              aspectRatio: 1.5
            )
          }
        }
        ... on ContentfulTextSection {
          id
          title
          backgroundColor
          paragraph {
            paragraph
          }
          image {
            title
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 80
              formats: [WEBP, AUTO]
              aspectRatio: 1
            )
          }
        }
        ... on ContentfulDonateFormHero {
          id
          __typename
          title
          giveText
          legalNonProfitText {
            legalNonProfitText
          }
          donateButtonText
          image: backgroundImage {
            title
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 80
              formats: [WEBP, AUTO]
              aspectRatio: 1.5
            )
          }
        }
        ... on ContentfulPhotoTextGrid {
          id
          gridLayout {
            titleText
            bodyText {
              raw
            }
            id
            photoSide
            photo {
              title
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 100
                formats: [WEBP, AUTO]
                placeholder: TRACED_SVG
                aspectRatio: 1
              )
            }
            ctaButton {
              id
              text
              color
              buttonType {
                __typename
                ... on ContentfulInternalLink {
                  id
                  name
                  link {
                    urlSlug
                  }
                }
                ... on ContentfulExternalLink {
                  id
                  name
                  externalLinkUrl {
                    externalLinkUrl
                  }
                }
              }
            }
          }
        }
      }
    }
    contentfulFlashMessage(name: { eq: "Flash Message" }) {
      name
      bannerLink
      text {
        raw
      }
    }
  }
`

export default ContentfulPage
